<template>
  <div class="card card-shadow">
    <div class="card-inner border-bottom">
      <div class="card-title-group">
        <div class="card-title">
          <div class="row">
              <img height="50px" src="@/assets/icons/1490540-reward-badges/png/041-trophy-5.png"/>
              <h6 class="title pt-3" style="padding-left: 10px;">
                  Top Desk Performers
              </h6>
          </div>
        </div>
      </div>
    </div>
    <ul class="nk-support">
      <li class="nk-support-item" v-for="(topPerformer, index) in topPerformers" :key="topPerformer.userName">
        <div class="">
          <avatar :src="topPerformer.userAvatarURI" style="margin:2px;" :size="40" :rounded="true" v-if="topPerformer.userName" :username="topPerformer.userName" />
        </div>
        <div class="nk-support-content">
          <div class="title">
            <span>{{topPerformer.userName}}</span>
          </div>
          <p>has closed a total of {{topPerformer.closedCount}} tickets this month!</p>
        </div>
        <div class="float-right">
          <img height="40px" width="40px" v-if="index === 0" src="@/assets/icons/1490540-reward-badges/png/050-gold-medal-3.png"/>
          <img height="40px" width="40px" v-if="index === 1" src="@/assets/icons/1490540-reward-badges/png/002-silver-medal.png"/>
          <img height="40px" width="40px" v-if="index === 2" src="@/assets/icons/1490540-reward-badges/png/003-bronze-medal.png"/>
        </div>
      </li>
    </ul>

    </div>
</template>

<script>
export default {
   data () {
     return {
       topPerformers: null,
     }
   },
   created() {
     this.getWidget()
   },
   methods: {
        getWidget () {
          this.$http.get('/widgets/Get_TopDeskPerformers')
            .then((response) => {
                this.topPerformers = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
   }
};
</script>

<style>
.topPerformer {
    font-size: 18px;
}
</style>
