<template>
  <div>
    <div>
      <div>
        <!-- <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">Support Tickets</h3>
            </div>
            <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#"
                  class="btn btn-icon btn-trigger toggle-expand mr-n1"
                  data-target="pageMenu"
                  ><em class="icon ni ni-more-v"></em
                ></a>
                <div class="toggle-expand-content" data-content="pageMenu">
                  <ul class="nk-block-tools g-3">
                    <li class="nk-block-tools-opt">
                      <router-link
                        :to="{ name: 'tickets' }"
                        class=" btn btn-secondary "
                        ><em class="icon ni ni-ticket"></em
                        ><span>Tickets</span></router-link
                      >
                    </li>
                    <li>
                    </li>
                </ul>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row g-gs">
          <div class="col-lg-8 col-xxl-8">
            <ticketsstatsbar></ticketsstatsbar>
            <div class="card">
              <ticketsopendclosedchart class="card-shadow"></ticketsopendclosedchart>
            </div>
            <div class="card"> 
              <ticketuserstatschart></ticketuserstatschart>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <router-link :to="{name: 'tickets'}"  class="btn btn-sm btn-dark btn-block mb-3"><em class="icon ni ni-ticket-alt-fill mr-1"></em> View Tickets</router-link>
            <topDeskPerformer/>
            <openedclosedcount></openedclosedcount>
            <ticketstatussummary></ticketstatussummary>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ticketsopendclosedchart from "@/components/widgets/desk/ticketsopenedclosedbydatestatschart";
import ticketstatussummary from "@/components/widgets/desk/ticketsstatslist";
import ticketsstatsbar from "@/components/widgets/desk/ticketsstatsbar2";
import openedclosedcount from "@/components/widgets/desk/openedclosedcount";
import ticketuserstatschart from "@/components/widgets/desk/ticketsByUsers.vue";
import topDeskPerformer from '@/components/widgets/desk/topDeskPerformer.vue'

export default {
  components: { ticketsopendclosedchart, ticketstatussummary, ticketsstatsbar, openedclosedcount, ticketuserstatschart, topDeskPerformer },
  data() {
    return {
      ticketStats: null,
      options: {
        colors: ["#758AFF", "#101924"],
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [
            "1st Jan",
            "2nd Jan",
            "3rd Jan",
            "4th Jan",
            "5th Jan",
            "6th Jan",
            "7th Jan",
            "8th Jan",
          ],
        },
      },
      series: [
        {
          name: "Tickets Complete",
          data: [15, 20, 36, 55, 70, 66, 56, 22],
        },
        {
          name: "Tickets Received",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
      ],
    };
  },
  created() { 
    // this.getTicketStats();
    this.getView()
  },
  methods: {
    getView() {
            this.$http.get('/ViewAccess/desk')
            .then(() => {
            })
            .catch(() => {
            })
        },
    // getTicketStats() {
    //   this.$http
    //     .get("/desk/Get_Ticket_Stats/")
    //     .then((response) => {
    //       this.ticketStats = response.data;
    //     })
    //     .catch(() => {
    //       this.$message.error("There has been an error");
    //     });
    // },
  },
};
</script>

<style></style>
