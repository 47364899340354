<template>
  <div v-if="stats" class="card  card-shadow">
    <div class="card-inner">
      <div class="analytic-ov">
        <div class="analytic-data-group analytic-ov-group g-3">
          <div class="analytic-data analytic-ov-data text-center">
            <div class="title ">Outstanding</div>
            <a-progress
              width="90px"
              type="circle"
              strokeColor="#6576FF"
              :percent="parseFloat((stats.outstandingCount / stats.totalCount) * 100)"
              :format="() =>  parseInt(stats.outstandingCount)"
            />
          </div>
          <div class="analytic-data analytic-ov-data text-center">
            <div class="title ">Completed</div>
            <a-progress
              width="90px"
              type="circle"
              strokeColor="#00E396"
              :percent="parseFloat((stats.completedCount / stats.totalCount) * 100)"
              :format="() =>  stats.completedCount"
            />
          </div>
          <!-- <div class="analytic-data analytic-ov-data text-center">
            <div class="title ">In-Progress</div>
            <a-progress
              width="90px"
              type="circle"
              strokeColor="#26A0FC"
              :percent="parseFloat((stats.inProgressCount / stats.totalCount) * 100)"
              :format="() =>  stats.inProgressCount"
            />
          </div> -->
          <div class="analytic-data analytic-ov-data text-center">
            <div class="title ">Overdue</div>
            <a-progress
              width="90px"
              type="circle"
              strokeColor="#FF4560"
              :percent="parseFloat((stats.overdueCount / stats.totalCount) * 100)"
              :format="() => stats.overdueCount"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      stats: null,
      widgetFetcher: {
          start: this.$moment().startOf('month')
      }
    }
  },
  created() {
    this.getTicketStats();
  },
  methods: {
    getTicketStats() {
      this.$http.post("/widgets/Get_ticketStatsBar/", this.widgetFetcher)
        .then((response) => {
          this.stats = response.data;
        });
    },
  },
};
</script>

<style></style>
