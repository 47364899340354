<template>
    <div class="card card-shadow mb-2">
      <div class="card-inner border-bottom">
        <div class="card-title-group">
          <div class="card-title">
            <h6 class="title">Current Ticket Status Summary</h6>
          </div>
        </div>
      </div>
      <div class="progress-list gy-3 card-body" style="margin-left: 5px; width:100%;">
        <div class="progress-wrap" v-for="status in statuses" v-bind:key="status.id">
          <div style="float:left; padding-right:10px;">{{status.name}}</div>
          <a-progress :strokeColor="status.color ? status.color : '#6576FF'" :percent="((100 / total) * status.ticketsCount).toFixed(2)" >
            <template #format="" >
              <span class="text-center">{{ status.ticketsCount }}</span>
            </template>
          </a-progress>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      statuses: null,
    };
  },
  computed: {
    total() {
      let totals = 0
      this.statuses.forEach(s => {
        totals += s.ticketsCount
      });
      return totals;
    },
  },
  created() {
    this.getTicketStats();
  },
  methods: {
    getTicketStats() {
      this.$http.get("/widgets/Get_TicketStatusSummary/")
        .then((response) => {
          this.statuses = response.data.ticketStatusSummaries;
        });
    },
  },
};
</script>

<style></style>
