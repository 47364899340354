<template>
  <div v-if="stats" class="mb-3 card card-shadow">
                    <div class="row g-4 card-inner">
                <div class="col-sm-12 col-xxl-12">
                  <div class="nk-order-ovwg-data buy">
                    <div class="amount">
                      {{stats.ticketsOpenedThisMonthCount}} <small class="currenct currency-usd">Opened this month</small>
                    </div>
                    <div class="info">
                      Last month
                      <strong
                        >{{stats.ticketsOpenedLastMonthCount}}
                        <span class="currenct currency-usd">Opened</span></strong
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-xxl-12">
                  <div class="nk-order-ovwg-data text-danger border-danger">
                    <div class="amount">
                      {{stats.ticketsClosedThisMonthCount}} <small class="currenct currency-usd">Closed this month</small>
                    </div>
                    <div class="info">
                      Last month
                      <strong
                        >{{stats.ticketsClosedLastMonthCount}}
                        <span class="currenct currency-usd">Closed</span></strong
                      >
                    </div>
                  </div>
                </div>
              </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      stats: null,
    }
  },
  created() {
    this.getTicketStats();
  },
  methods: {
    getTicketStats() {
      this.$http.get("/widgets/Get_openedClosedCount/")
        .then((response) => {
          this.stats = response.data;
        });
    },
  },
};
</script>
<style>

</style>